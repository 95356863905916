<template>
  <transition name="slide">
    <div
      v-lazy-container="{selector: 'img'}"
      v-if="open" class="fixed top-0 flex right-0 flex-col z-50 h-screen w-fit-content bg-white drawer overflow-y-auto py-5"
      :class="containerClassname"
      :style="drawerStyle"
      ref="drawer">
      <slot name="header">
        <div class="header">
          <IconButton
            icon="close"
            icon-classname="!text-space-dark-blue !h-full !w-8"
            @click="close"
          />
        </div>
      </slot>
      <slot name="content" v-on="$listeners"/>
    </div>
  </transition>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";

export default {
  name: 'drawer',
  components: {IconButton},
  emits: ['close'],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    containerClassname: {
      type: String,
      default: ''
    },
    drawerStyle: {
      type: Object,
      default: () => ({
        minWidth: '15vw'
      })
    }
  },
  destroyed() {
    document.body.classList.remove('scrollbar-hidden');
  },
  watch: {
    open() {
      if (this.open) {
        document.body.classList.add('scrollbar-hidden');
        //nextick
        setTimeout(() => {
          // TODO add outside click listener
          document.addEventListener('keydown', this.handleEscKey);
        }, 300);
      } else {
        document.body.classList.remove('scrollbar-hidden');
        document.removeEventListener('keydown', this.handleEscKey);
      }
    }
  },
  methods: {
    handleEscKey(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.drawer {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
}

/* drawer mobile */
@media screen and (max-width: 640px) {
  .drawer {
    min-width: 100vw !important;
  }
}

@media screen and (min-width: 640px) {
  .drawer {
    min-width: 15vw;
    max-width: 20vw;
  }
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .drawer {
    min-width: 15vw;
    max-width: 45vw;
  }
}

/* Values for vue slide transition */
.slide-enter-active {
  transition: all 0.2s ease;
  transform: translateX(100%);
  opacity: 1;
}

.slide-enter-to {
  transition: all 0.3s ease;
  transform: translateX(0%);
}

.slide-leave-active {
  transition: all 0.4s ease;
  transform: translateX(100%);
  opacity: 0;
}
</style>
