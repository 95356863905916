<template>
  <Tooltip :text="tooltipText" :enabled="!!tooltipText">
    <template #content>
      <div
        class="w-8 h-8 flex justify-center items-center rounded-full cursor-pointer"
        :class="classname"
        @click.stop="onClick"
      >
        <font-awesome-icon
          :icon="icon"
          :class="iconClassname"
          class="h-1/2 w-1/2 text-white"
        />
      </div>
    </template>
  </Tooltip>
</template>
<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import Tooltip from "@/components/tooltips/Tooltip.vue";

export default {
  name: 'IconButton',
  components: {Tooltip, FontAwesomeIcon},
  props: {
    classname: {
      type: String,
    },
    iconClassname: {
      type: String,
      default: 'text-white'
    },
    icon: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String
    }
  },
  emits: ['click'],
  methods:{
    onClick($event){
      this.$emit('click', $event);
    }
  }
};
</script>
