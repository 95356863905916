<template>
  <div
    @mouseover="() => handleMouseEvent('mouseover')"
    @mouseleave="() => handleMouseEvent('mouseleave')"
    ref="tooltipWrapper"
    @click.stop="preventDefault"
    class="!mt-0"
  >
    <slot name="content" class="flex tooltip"/>
    <div
      ref="tooltip"
      id="tooltip"
      data-popper-placement="bottom"
      class="bg-gray-900 z-40 rounded-md px-4 py-1 drop-shadow-2xl"
      :class="!show ? 'hidden' : 'absolute'"
    >
      <slot name="tooltipContent">
        <span class="!text-white text-space-dark-blue flex w-full">{{ text }}</span>
      </slot>
      <div ref="arrow" class="absolute bg-gray-900 h-2 w-2 rotate-45"></div>
    </div>
  </div>
</template>
<script>

import {arrow, computePosition, flip, offset} from "@floating-ui/vue";

export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  updated() {
    if (!this.$refs || !this.$refs.tooltipWrapper || !this.$refs.tooltip) return;
    computePosition(this.$refs.tooltipWrapper, this.$refs.tooltip, {
      middleware: [
        offset(4),
        flip(),
        arrow({element: this.$refs.arrow})],
    })
      .then(({x, y, middlewareData, placement}) => {
        Object.assign(this.$refs.tooltip.style, {top: `${y}px`, left: `${x}px`});

        const opposedSide = {
          top: 'bottom',
          bottom: 'top',
          left: 'right',
          right: 'left'
        }[placement.split('-')[0]];

        const {x: arrowX, y: arrowY} = middlewareData.arrow;
        Object.assign(this.$refs.arrow.style, {
          left: arrowX ? `${arrowX}px` : '',
          top: arrowY ? `${arrowY}px` : '',
          bottom: '',
          right: '',
          [opposedSide]: '-4px',
        });
      });
  },
  methods: {
    handleMouseEvent(event) {
      if (!this.enabled) return;
      if (event === 'mouseover') {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    preventDefault(e) {
      e.preventDefault();
    }
  },
  data() {
    return {
      show: false
    };
  }
};
</script>
